<template>
  <span class="uppercase">{{ author?.name }}</span>
</template>

<script setup lang="ts">
import type { WP_REST_API_User } from 'wp-types';

const props = defineProps<{
  id: number;
}>();

const { public: { baseURL } } = useRuntimeConfig();

const { data: author } = useNuxtData<WP_REST_API_User>(`author-name-${props.id}`);

await useFetch(`/wp/v2/users/${props.id}`, {
  baseURL,
  key: `author-name-${props.id}`,
  query: {
    _fields: 'id,name',
  },
  default() {
    return author.value;
  },
});
</script>